<template>
  <div class="Login">
    <div class="main">
      <div class="Phone">
        <span class="img"><img src="../../../public/images/Login/user.png" /></span>
        <input v-model="Phone" placeholder="请输入手机号码"/>
      </div>
      <div class="Code">
        <span class="img"><img src="../../../public/images/Login/code.png" /></span>
        <input v-model="Code" placeholder="请输入短信验证码"/>
        <div class="setCode" @click="setCode">{{ StartText }}</div>
      </div>

      <div class="Agreement">
        <input name="Fruit" type="checkbox" v-model="LawStart"/>
        <span class="word">勾选同意<a @click="getlaw('protocol')">《用户服务协议》</a><a
            @click="getlaw('privacy')">《用户隐私政策》</a></span>
      </div>

      <div class="logon" @click="login">登陆</div>

    </div>
  </div>
</template>

<script>
import {Dialog, Notify} from "vant";
import {sendingCode, verifyCode} from "@/api/Login";


export default {
  data() {
    return {
      Start: false,
      Phone: '',
      Code: '',
      StartText: '获取验证码',
      LawStart: false,
      Time: 0,
    }
  },
  components: {
    [Notify.Component.name]:
    Notify.Component,
    [Dialog.Component.name]:
    Dialog.Component,
  },
  mounted() {
    let info = this.$store.state.user.info

    if (info.Phone) {
      setTimeout(() => {
        this.$router.push('/Main/Home')
      }, 200)
    }
  },
  methods: {
    getlaw() {

    },
    login() {
      if (!this.LawStart) {
        Dialog.alert({
          message: '请勾选协议政策',
        });
        return;
      }

      let Phone = this.Phone
      let Code = this.Code

      if (!Phone) {
        Dialog.alert({
          message: '请填写手机号码',
        });
        return;
      }

      if (!Code) {
        Dialog.alert({
          message: '请填写验证码',
        });
        return;
      }

      verifyCode({Phone, Code}).then(res => {
        if (res.errorCode == 200) {
          let payload = res.payload
          Notify({type: "success", message: payload.message});
          let info = payload.info
          this.$store.state.user.info = info

          localStorage.setItem('info', JSON.stringify(info));

          setTimeout(() => {
            this.$router.push('/Main/Home')
          }, 200)
        } else {
          Dialog.alert({
            message: res.errorMsg,
          });
        }
      })
    },
    //发送验证码
    setCode() {
      let time = this.Time
      if (!this.Phone) {
        Dialog.alert({
          message: '未输入手机号码',
        });
        return;
      }
      if (time == 0) {
        if (/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(this.Phone)) {
          let Phone = this.Phone
          sendingCode({Phone}).then(res => {
            if (res.errorCode == 200) {
              this.SetTime()
              Dialog.alert({
                message: res.payload,
              });
            } else {
              Dialog.alert({
                message: res.errorMsg,
              });
            }
          })
        } else {
          Dialog.alert({
            message: '手机号码不符合规则',
          });
        }
      }
    },
    //开始计时
    SetTime() {
      this.Time = 60
      this.Start = true
      this.CodeTime = setInterval(() => {
        let times = this.Time
        times = times - 1
        if (times == 0) {
          clearInterval(this.CodeTime)
          this.StartText = '获取验证码'
          this.Time = 0
          return
        }
        this.Time = times
        this.StartText = times + '秒再次发送'
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.Login {
  width: 100%;
  height: 100%;
  background-color: blueviolet;
}


.main {
  height: 300px;
  width: 310px;
  background-color: #000;
  align-self: center;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -155px;
  margin-top: -150px;
  border-radius: 15px;

  .Phone {
    height: 40px;
    background-color: #000;
    border: 2px solid rgba(159, 159, 159, 0.5);
    width: 267px;
    position: absolute;
    left: 20px;
    top: 60px;
    border-radius: 15px;

    .img {
      width: 40px;
      height: 40px;
      display: block;
      //background-color: rgba(210, 210, 210, 1);
      font-size: 14px;
      line-height: 40px;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      img{
        width: 70%;
        height: 70%;
        position: absolute;
        left: 10px;
        top: 5px;
      }
    }

    input {
      color: rgb(159, 159, 159);
      background: none;
      top: 0;
      position: absolute;
      border: none;
      left: 50px;
      width: 210px;
      height: 40px;
      outline: none;
      font-size: 14px;
      padding: 0;
    }
  }

  .Code {
    height: 40px;
    background-color: #000;
    border: 2px solid rgba(159, 159, 159, 0.5);
    width: 267px;
    position: absolute;
    left: 20px;
    top: 120px;
    border-radius: 15px;

    .img {
      width: 40px;
      height: 40px;
      display: block;
      font-size: 14px;
      line-height: 40px;
      position: absolute;
      left: 0;
      text-align: center;
      img{
        position: absolute;
        left: 10px;
        top: 5px;
        width: 80%;
        height: 80%;
      }
    }

    input {
      color: rgb(159, 159, 159);
      background: none;
      top: 0;
      position: absolute;
      border: none;
      left: 50px;
      width: 120px;
      height: 40px;
      outline: none;
      font-size: 14px;
      padding: 0;
    }

    .setCode {
      position: absolute;
      left: 170px;
      font-size: 12px;
      color: rgb(159, 159, 159);
      width: 90px;
      height: 40px;
      text-align: center;
      line-height: 40px;
    }
  }

  .main3 {
    width: 107px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    position: absolute;
    left: 180px;
    top: 120px;
    color: #1989fa;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
  }

  .Agreement {
    width: 257px;
    height: 24px;
    position: absolute;
    font-size: 12px;
    top: 180px;
    left: 20px;
    color: rgb(159, 159, 159);

    a {
      color: rgb(159, 159, 159);
    }
  }

  .logon {
    height: 35px;
    background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
    color: #FFFFFF;
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    width: 100px;
    position: absolute;
    text-align: center;
    margin-left: 105px;
    top: 245px;
    line-height: 35px;
    font-size: 14px;
    border-radius: 10px;
  }


}
</style>
