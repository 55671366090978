import request from '../utils/request';

//发送短信验证码
export const sendingCode = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Login/sendingCode',
        method: 'post',
        data: query
    });
};


//验证验证码
export const verifyCode = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Login/verifyCode',
        method: 'post',
        data: query
    });
};